<template>
    <div class="container">
        <header class="jumbotron">
            <h3>Lista raportów Szczegóły</h3>
        </header>
        <div style="height: calc(100vh - 143px)">
            <DataTable :value="content" :lazy="true" :paginator="true" :rows="lazyParams.limit"
                       :totalRecords="totalRecords" @page="onPage($event)" :loading="loading" responsiveLayout="scroll"
                       class="padding3" :scrollable="true" scrollHeight="flex">
                <template #empty>
                    Nie znaleziono danych
                </template>
                <template #loading>
                    Ładwanie raportów. Proszę czekać
                </template>
                <Column field="place" header="Miejscowość"></Column>
                <Column field="zipCode" header="Kod pocztowy"></Column>
                <Column field="street" header="Ulica"></Column>
                <Column field="buildingNumber" header="Nr domu"></Column>
                <Column field="apartmentNumber" header="Nr mieszkania"></Column>
                <Column field="waterConsumption" header="Zużycie wody" v-if="this.reportType === 'woda'"></Column>
                <Column field="waterConsumptionPerPerson" header="Średnie zużycie wody na mieszkańca"
                        v-if="this.reportType === 'woda'"></Column>
                <Column field="declaredResidents" header="Deklarowana liczba mieszkańców"
                        v-if="this.reportType === 'woda'"></Column>
                <!--            <Column field="counted" header="Średnie zużycie wody na mieszkańca" v-if="this.reportType === 'woda'" ></Column>-->
                <Column field="declared" header="Liczba osób zadeklarowana" v-if="this.reportType !== 'woda'"></Column>
                <Column field="counted" header="Liczba osób wyliczona" v-if="this.reportType !== 'woda'"></Column>
                <Column headerStyle="width: 14em" bodyStyle="text-align: center; display: inline-block;">
                    <template #body="slotProps">
                        <Button type="button" label="Pokaż"
                                v-on:click="showReort(slotProps.data.id)"></Button>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
    import ReportService from "../../services/reports.service";
    import {FilterMatchMode} from 'primevue/api';

    export default {
        inject: ['global'],
        name: "ReportDetailsList",
        data() {
            return {
                loading: false,
                totalRecords: 0,
                content: "",
                meta: null,
                reportType: 'default',
                lazyParams: {
                    limit: 100,
                    page: 1
                },
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
                },
            };
        },
        methods: {
            showReort: function (id) {
                if (this.reportType === 'woda') {
                    this.$router.push({path: `${this.global.state.instancePatch}/raporty/woda/lista/${this.$route.params.reportId}/lista/${id}`});
                } else {
                    this.$router.push({path: `${this.global.state.instancePatch}/raporty/deklaracje/lista/${this.$route.params.reportId}/lista/${id}`});
                }
            },
            onPage(event) {
                console.log(event);
                this.lazyParams.page = event.page + 1;
                this.getList();
            },
            getList: function () {
                this.loading = true;
                this.reportType = this.$route.params.type === 'woda' ? 'woda' : 'default';
                if (this.reportType === 'woda') {
                    ReportService.getReportDetailsListWater(this.$route.params.reportId, this.lazyParams.limit, this.lazyParams.page).then(
                        (response) => {
                            this.meta = response.data.meta;
                            this.totalRecords = this.meta.totalItems;
                            this.loading = false;
                            this.content = response.data.items;
                            this.content = this.content.map(item => ({
                                id: item.id,
                                createdAt: new Date(item.createdAt).toISOString().split('T')[0],
                                year: item.year,
                                quarter: item.quarter,
                                place: item.place,
                                zipCode: item.zipCode,
                                street: item.street,
                                buildingNumber: item.buildingNumber,
                                apartmentNumber: item.apartmentNumber,
                                counted: item.data.counted,
                                declared: item.data.declared,
                                waterConsumption: item.data.waterConsumption ? parseFloat(item.data.waterConsumption).toFixed(2) : "Brak deklaracji",
                                waterConsumptionPerPerson: item.data.waterConsumptionPerPerson ? parseFloat(item.data.waterConsumptionPerPerson).toFixed(2) : "Brak deklaracji",
                                declaredResidents: item.data.declaredResidents ? item.data.declaredResidents: "Brak deklaracji",
                            }));
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                } else {
                    ReportService.getReportDetailsList(this.$route.params.reportId, this.lazyParams.limit, this.lazyParams.page).then(
                        (response) => {
                            this.meta = response.data.meta;
                            this.totalRecords = this.meta.totalItems;
                            this.loading = false;
                            this.content = response.data.items;
                            this.content = this.content.map(item => ({
                                id: item.id,
                                createdAt: new Date(item.createdAt).toISOString().split('T')[0],
                                year: item.year,
                                quarter: item.quarter,
                                place: item.place,
                                zipCode: item.zipCode,
                                street: item.street,
                                buildingNumber: item.buildingNumber,
                                apartmentNumber: item.apartmentNumber,
                                counted: item.data.counted,
                                declared: item.data.declared
                            }));
                        },
                        (error) => {
                            this.content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                        }
                    );
                }
            },
        },
        mounted() {
            this.loading = true;
            this.getList();
        },
    };
</script>
